import { Avatar, Collapse, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { sistema } from "../../model/Sistema";


export default ({ habilitado, numero, disabled, posicion, status, usuarios, reservas, usuariosPosibles, onClick, seleccionados, ...props }) => {
  const seleccionado = seleccionados.find(item => item.id === numero);
  const [open, setOpen] = useState(false);
  const usuario = sistema?.actual?.usuarioAsignado ?? sistema.usuario;
  const puedeAdministrar = sistema?.usuario?.contratoAdminAccess ?? false;
  const esFreelancer = sistema?.usuario?.contrato?.cliente?.tipo === 'FREELANCER';
  const [usuarioPosible, setUsuarioPosible] = useState(false);
  const [statusCalculado, setStatusCalculado] = useState(status);
  const asistio = sistema?.actual?.asistio ?? true;
  const cancelando = sistema?.actual?.cancelando ?? false;

  const imagenDeUsuarios = (usuarios) => {
    //TODO: Re-armar para mostrar todos los usuarios
    // return usuarios && (usuarios.length > 0) ? usuarios[0].profile_photo : null;
    return usuarios && (usuarios.length > 0) ? (usuarios[0].imagen ?? usuarios[0].profile_photo) : null;
  };

  const nombreDeUsuarios = (usuarios) => {
    return usuarios && (usuarios.length === 1) ? usuarios[0].nombres : '';
  }

  const handleClick = () => {
    if (!!disabled) {
      // La mesa esta deshabilitada (ej: por no poder reservar en el dia)
      return;
    }
    if (!!reservas && reservas.length > 0) {
      // No se puede seleccionar el puesto porque tiene reservas anteriores
      // reemplazo de pointerEvents: (!seleccionado || (!!reservas && reservas.length >0) ? 'none' : '')
      return;
    }
    if (!!puedeAdministrar && !esFreelancer && (!cancelando || (cancelando !== 'transferir') || !asistio)) {
      console.log(usuariosPosibles, usuarios, seleccionados);
      setOpen(true)
    } else {
      if (!usuarios || (usuarios[0].id !== usuario.id)) {
        handleUsuarioSeleccionado(usuario);
      } else {
        handleUsuarioSeleccionado(null);
      }
    }
  }

  const handleUsuarioSeleccionado = usuario => {
    onClick(usuario);
    setOpen(false);
  }

  useEffect(() => {
    setUsuarioPosible(!usuarios || (usuarios.length === 0) || !!usuariosPosibles.find(item => item.id === usuarios[0].id));
  }, []);

  useEffect(() => {
    let estado = status;
    if (estado === 'TAKEN') {
      // Esta tomado, verificamos si es por un usuario que puede tomarlo y no es una reserva anterior
      if (!reservas || reservas.length === 0) {
        // No tenia reservas, vemos si el usuario esta dentro de los posibles
        if (!usuariosPosibles.find(item => item.id === usuarios[0].id)) {
          // No era posible reservar para hoy
          estado = 'FREE';
        }
      }
    }
    setStatusCalculado(estado);
  }, [status, usuariosPosibles, reservas, usuarios]);

  return (
    <div className={"escritorio escritorio" + (!habilitado ? '--inhabilitado' : ((statusCalculado === 'FREE') ? '--habilitado' : (!!seleccionado && (!reservas || reservas.length === 0) ? '--reservado' : '--ocupado')))}>
      <span className="escritorio__posicion">{numero}</span>
      {!!puedeAdministrar && !esFreelancer && (!cancelando || (cancelando !== 'transferir') || !asistio) && open && <Dialog open={open} onClose={() => {setOpen(false)}}>
        <DialogTitle>Asigne el puesto</DialogTitle>
        <DialogContent>
          {!!usuariosPosibles && (!!usuariosPosibles.length > 0) &&
            <List component="nav" sx={{ zIndex: "100" }} >
              {/* sort((a ,b) => {return !!usuarios && (usuarios.length > 0) && (usuarios[0].id === a.id) ? -1 : 0 }). */}
                  {usuariosPosibles.filter(usuario => (!!usuarios && (usuarios.length > 0) && (usuarios[0].id === usuario.id)) || !seleccionados.find(puesto => puesto.usuarios.find(item => item.id === usuario.id))).map((usuario, index) => {
                    return <ListItem key={index} >
                      <ListItemButton onClick={() => handleUsuarioSeleccionado(usuario)} >
                        {/* <ListItemText sx={{ maxWidth: '120px', display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{usuario.nombres + ' ' + usuario.apellidos}</ListItemText> */}
                        <Typography className="escritorio__ocupante-nombre__multi" component="p">{usuario.nombres + ' ' + usuario.apellidos}</Typography>
                        {!!(usuario.imagen ?? usuario.profile_photo)
                          ? < ListItemAvatar > <Avatar className="escritorio__ocupante-avatar-image__multi" alt={usuario.nombres + ' ' + usuario.apellidos} src={usuario.imagen ?? usuario.profile_photo} /></ListItemAvatar>
                          : <div className="escritorio__ocupantes-avatar-image" title={usuario && usuario.nombres + ' ' + usuario.apellidos}><span className="escritorio__ocupantes-avatar-image__text">{usuario && usuario.nombres[0]+usuario.apellidos[0]}</span></div>
                        }
                      </ListItemButton>
                        {!!usuarios && (usuarios.length > 0) && (usuario.id === usuarios[0].id) &&
                      <ListItemButton onClick={() => handleUsuarioSeleccionado(null)} sx={{position: 'absolute', right: 0}}><i className="icon--times" /></ListItemButton>}
                    </ListItem>
                  }
                  )}
            </List>
          }
        </DialogContent>
      </Dialog>}
      {(statusCalculado === 'TAKEN') && !!usuarios
        ? <ListItemButton disabled={disabled || !habilitado} sx={{ padding: 0, width: '100%', height: '100%', justifyContent: 'center' }} disableGutters onClick={handleClick}>
          {imagenDeUsuarios(usuarios)
            ? <ListItemAvatar className="escritorio__ocupantes-avatar" title={usuarios[0].nombres + ' ' + usuarios[0].apellidos}><Avatar className="escritorio__ocupantes-avatar-image" sx={{ width: '56px', height: '56px' }} alt={usuarios[0].nombres + ' ' + usuarios[0].apellidos} src={usuarios[0].imagen ?? usuarios[0].profile_photo} /></ListItemAvatar>
            : <div className="escritorio__ocupantes-avatar-image" title={usuarios && usuarios[0].nombres + ' ' + usuarios[0].apellidos}><span className="escritorio__ocupantes-avatar-image__text">{usuarios && (usuarios.length > 0) ? usuarios[0].nombres[0]+usuarios[0].apellidos[0] : null}</span></div>
          }
          </ListItemButton>
        : <ListItemButton disabled={disabled || !habilitado} sx={{ padding: 0, width: '100%', height: '100%', justifyContent: 'center' }} disableGutters className={(!open ? ' is-folded' : ' is-unfolded')} onClick={handleClick}>
          {/* <img className="selector-chevron" src="/img/chevron.svg" alt="" /> */}
        </ListItemButton>
      }
      {/*
        <button className="escritorio__control" disabled={!habilitado || (status !== 'FREE')} onClick={onClick}>
          <span className="escritorio__posicion">{numero}</span>
          <div className="escritorio__ocupantes">
            {imagenDeUsuarios(usuarios)
              ? <img className="escritorio__ocupantes-avatar-image" src={imagenDeUsuarios(usuarios)} alt="" />
              : <div className="escritorio__ocupantes-avatar-image"><span className="escritorio__ocupantes-avatar-image__text">{usuarios && (usuarios.length > 0) ? usuarios[0].nombres[0]+usuarios[0].apellidos[0] : null}</span></div>
            }
            <div className="escritorio__ocupantes-nombre">
              <span className={"nombres--" + (usuarios ? usuarios.length : 0)}>{nombreDeUsuarios(usuarios)}</span>
            </div>
          </div>
        </button>
         */}
      <div className={"silla silla--" + posicion}><img className="silla__image" src="/img/silla.png" alt="" /></div>
    </div>
  );
}