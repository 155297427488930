import React, { useEffect, useRef, useState } from "react";
import FixedCTA from "../../components/FixedCTA";
import Selector from "../../components/Selector";
import { sistema } from "../../model/Sistema";
import { sendRequest } from '../../lib/serverAPI';
import Membresia from "../../components/Membresia";
import TituloConBack from "../../components/TituloConBack";
import APIManager from '../../managers/APIManager';
import TarjetaDeEmpleado from "./components/TarjetaDeEmpleado";
import TarjetaDeContrato from "./components/TarjetaDeContrato";
import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Toolbar } from "@mui/material";
import { variablesAplicadas } from "../../lib/templateFunctions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ordenPorNombreYApellido } from "../../lib/sortFunctions";
// import { Close as CloseIcon } from "@mui/icons-material";

export default ({usuario, setUsuario, ...props}) => {
  const [nombres, setNombres] = useState(usuario.nombres);
  const [apellidos, setApellidos] = useState(usuario.apellidos);
  const [emailDeContacto, setEmailDeContacto] = useState(usuario.emailDeContacto);
  const [telefono, setTelefono] = useState(usuario.telefono);
  const [espacios, setEspacios] = useState(sistema.espacios);
  const [espacio, setEspacio] = useState(sistema.espacio);
  const [aspectosProfesionales, setAspectosProfesionales] = useState(usuario.aspectosProfesionales);
  const [professionalHighlights, setProfessionalHighlights] = useState(usuario.professionalHighlights);
  const [professionalKeywords, setProfessionalKeywords] = useState(usuario.professionalKeywords);
  const [aptitudes, setAptitudes] = useState(usuario.aptitudes);
  const [aptitud, setAptitud] = useState(null);
  const [aptitudesGenerales, setAptitudesGenerales] = useState([]);

  const [miembroARemover, setMiembroARemover] = useState(null);
  const [showConfirmarRemover, setShowConfirmarRemover] = useState(false);

  const [avatar, setAvatar] = useState({ url: usuario.avatar });
  const [imagenCambiada, setImagenCambiada] = useState(false);

  const inputRef = useRef(null);

  const [datosCompletos, setDatosCompletos] = useState(false);

  const [viendoIndividuo, setViendoIndividuo] = useState(true);

  const [empresa, setEmpresa] = useState(null);

  const [nombreDeEmpresa, setNombreDeEmpresa] = useState('');
  const [descripcionDeEmpresa, setDescripcionDeEmpresa] = useState('');
  const [webDeEmpresa, setWEBDeEmpresa] = useState('');
  const [empleados, setEmpleados] = useState([]);
  const [empleadosPendientes, setEmpleadosPendientes] = useState([]);
  const [staff, setStaff] = useState([]);
  const [nuevoAdministrador, setNuevoAdministrador] = useState(null);
  const [agregandoAdministrador, setAgregandoAdministrador] = useState(false);

  const [adminAccess, setAdminAccess] = useState(false);

  const [documentoContent, setDocumentoContent] = useState('');
  const [viendoContrato, setViendoContrato] = useState(false);

  const history = useHistory();

  const usuarioModificado = () => {
    return (nombres !== usuario.nombres)
      || (apellidos !== usuario.apellidos)
      || (emailDeContacto !== usuario.emailDeContacto)
      || (espacio !== sistema.espacio)
      || (aspectosProfesionales !== usuario.aspectosProfesionales)
      || (professionalKeywords !== usuario.professionalKeywords)
      || (professionalHighlights !== usuario.professionalHighlights)
      || (aptitudes !== usuario.aptitudes)
      || (telefono !== usuario.telefono)
      || imagenCambiada
  }

  const empresaModificada = () => {
    if (!!empresa) {
      return (nombreDeEmpresa !== empresa.nombre)
        || (descripcionDeEmpresa !== empresa.descripcion)
        || (webDeEmpresa !== empresa.web)
    } else {
      return false;
    }

  }

  useEffect(() => {
    setDatosCompletos(viendoIndividuo ? (usuarioModificado() && nombres && apellidos && espacio && emailDeContacto) : empresaModificada());
  }, [viendoIndividuo, nombres, apellidos, espacio, emailDeContacto, aspectosProfesionales, professionalHighlights, professionalKeywords, aptitudes, telefono, nombreDeEmpresa, imagenCambiada]);

  const quitarAptitud = (item) => {
    setAptitudes(aptitudes.filter(aptitud => aptitud.id !== item.id));
  }

  const agregarAptitud = (item) => {
    setAptitud(null);
    setAptitudes([...aptitudes, item]);
  }

  const guardarCambios = () => {

    sistema.actual.usuario = usuario;
    sistema.actual.usuario.nombres = nombres;
    sistema.actual.usuario.apellidos = apellidos;
    sistema.actual.usuario.emailDeContacto = emailDeContacto;
    sistema.actual.usuario.espacio = espacio;
    sistema.actual.usuario.aspectosProfesionales = aspectosProfesionales;
    sistema.actual.usuario.professionalHighlights = professionalHighlights;
    sistema.actual.usuario.professionalKeywords = professionalKeywords;
    sistema.actual.usuario.aptitudes = aptitudes;
    sistema.actual.usuario.telefono = telefono;
    sistema.actual.usuario.avatar = avatar.url;

    sistema.saveActual();

    sistema.actual.usuario.avatar = avatar;
    sendRequest('post', 'usuario','modificarUsuario',sistema.actual,(response) => {
      if (response.success) {
        sistema.espacio = espacio;
        sistema.actual.usuario.avatar = avatar.url;
        sistema.saveActual();
        setImagenCambiada(false);
        setDatosCompletos(false);
        sistema.refreshUser(setUsuario);
      }
    });
  }

  const agregarAdministrador = () => {
    if (!!nuevoAdministrador) {
      sendRequest('post', 'empresa', 'agregarStaff', { empresaID: usuario.empresa.id, staffID: nuevoAdministrador.id }, response => {
        if (response.success) {
          setNuevoAdministrador(null);
          setAgregandoAdministrador(false);
          cargarEmpresa();
        }
      });
    }
  }

  const removerAdministrador = (staffID) => {
    sendRequest('post', 'empresa', 'removerStaff', { empresaID: usuario.empresa.id, staffID }, response => {
      if (response.success) {
        cargarEmpresa();
      }
    });
  }

  const aceptarMiembro = (miembro) => {
    sendRequest('post', 'empresa', 'aceptarEmpleado', { empresaID: usuario.empresa.id, empleado: miembro }, response => {
      if (response.success) {
        cargarEmpresa();
      }
    });
  }

  const confirmarRemover = (miembro) => {
    setMiembroARemover(miembro);
    setShowConfirmarRemover(true);
  }

  const rechazarMiembro = (miembro, callback = null) => {
    sendRequest('post', 'empresa', 'rechazarEmpleado', { empresaID: usuario.empresa.id, empleado: miembro }, response => {
      if (response.success) {
        if (!!callback) {
          callback();
        }
        cargarEmpresa();
      }
    });
  }

  const toggleAccesoACuenta = (miembro) => {
    sendRequest('post', 'empresa', 'accesoACuenta', { empresaID: usuario.empresa.id, empleado: miembro, permitido: !miembro.accesoACuenta }, response => {
      if (response.success) {
        cargarEmpresa();
      }
    });
  }

  const cargarAptitudes = () => {
    APIManager.getList('skills', {}, (response) => {
      setAptitudesGenerales(response.data.listado.map(item => {
        item.nombre = item.name;
        return item;
      }));
    })
  }

  const cargarEmpresa = () => {
    sendRequest('post', 'empresa', 'getEmpresa', { empresaID: usuario.empresa.id }, response => {
      if (response.success && response.data.empresa) {
        response.data.empresa.empleados = Object.values(response.data.empresa.empleados);
        response.data.empresa.empleados = response.data.empresa.empleados.filter(item => item.activo);
        response.data.empresa.staff = Object.values(response.data.empresa.staff);
        setEmpresa(response.data.empresa);
      }
    });
  }

  const handleCloseConfirmarRemover = () => {
    setMiembroARemover(null);
    setShowConfirmarRemover(false);
  }

  const handleCloseContrato = () => {
    setViendoContrato(false);
  }

  const handleConfirmarRemover = () => {
    rechazarMiembro(miembroARemover, handleCloseConfirmarRemover);
  }

  const handleVerContrato = () => {
    if (!!usuario.contrato.documentoURLFull) {
      window.open(usuario.contrato.documentoURLFull);
    } else if (!!usuario.contrato.template_id) {
      sendRequest('post', 'contratos', 'getTemplate', { id: usuario.contrato.template_id }, response => {
        if (response.success) {
          const template = response.data.template;
          variablesAplicadas(template, [], usuario.contrato, htmlBody => {
            setDocumentoContent(htmlBody);
            setViendoContrato(true);
          });
        }
      });
    }
  }

  const handleFirmarContrato = () => {
    history.push('/firmar');
  }

  useEffect(() => {
    if (!viendoIndividuo && !empresa) {
      cargarEmpresa();
    }
  }, [viendoIndividuo]);

  useEffect(() => {
    if (!!empresa) {
      setNombreDeEmpresa(empresa.nombre);
      setWEBDeEmpresa(empresa.web);
      setDescripcionDeEmpresa(empresa.descripcion);
      setEmpleados(empresa.empleados.filter(item => item.activo && !item.pendiente).map(item => {
        item.nombre = item.nombres + ' ' + item.apellidos;
        return item;
      }));
      setEmpleadosPendientes(empresa.empleados.filter(item => item.activo && item.pendiente).map(item => {
        item.nombre = item.nombres + ' ' + item.apellidos;
        return item;
      }));
      setStaff(empresa.staff);
      setAdminAccess(!!empresa.staff.find(item => item.id === usuario.id));
    }
  }, [empresa]);

  const cambiarImagen = async (event) => {
    // setImagenCambiada(true);
    const imageFiles = event.target.files;
    if (imageFiles.length > 0) {
      const base64file = await toBase64(imageFiles[0]);
      setAvatar({ file: base64file, name: imageFiles[0].name, tipo: 'avatarDeUsuario', id: usuario.id, url: URL.createObjectURL(imageFiles[0]) });
      setImagenCambiada(true);
    }

  }
/* // WIP
  const cambiarImagenEmpresa = async (event) => {
    // setImagenCambiada(true);
    const imageFiles = event.target.files;
    if (imageFiles.length > 0) {
      const base64file = await toBase64(imageFiles[0]);
      setAvatar({ file: base64file, name: imageFiles[0].name, tipo: 'avatarDeEmpresa', id: empresa.id, url: URL.createObjectURL(imageFiles[0]) });
      setImagenCambiada(true);
    }

  }
 */
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

  useEffect(() => {
    cargarAptitudes();
  }, []);

  useEffect(() => {
    if (!!usuario) {
      if (!!usuario.contrato && (usuario.contrato.cliente.tipo !== 'EMPRESA')) {
        setAdminAccess(true);
      }
    }
  }, [usuario]);

  return (
    <div className="perfil-page">
      <TituloConBack titulo="Perfil" />
      {!!viendoContrato && !!documentoContent &&
        <Dialog
          fullScreen
          open={viendoContrato} onClose={handleCloseContrato}>
          <AppBar sx={{ position: 'relative' }} >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseContrato}
                aria-label="close"
              >
                <i className="icon--times"></i>
                {/* <CloseIcon /> */}
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Paper elevation={3} sx={{ padding: '24px' }} dangerouslySetInnerHTML={{ __html: documentoContent }} />
          </DialogContent>
          <DialogActions><Button onClick={handleCloseContrato}>Cerrar</Button></DialogActions>
        </Dialog>
      }
      {!!showConfirmarRemover && !!miembroARemover &&
        <Dialog
          fullScreen
          open={showConfirmarRemover} onClose={handleCloseConfirmarRemover}>
          <AppBar sx={{ position: 'relative' }} >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseConfirmarRemover}
                aria-label="close"
              >
                <i className="icon--times"></i>
                {/* <CloseIcon /> */}
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContentText>
            <p>Confirma quitar a </p>
          </DialogContentText>
          <DialogActions><Button onClick={handleConfirmarRemover}>Confirmar</Button></DialogActions>
        </Dialog>
      }
      {!!usuario.empresa && <div className="tipo-de-perfil--selector">
        <div className={"selector-link" + (viendoIndividuo ? ' is--selected' : '')} onClick={() => {setViendoIndividuo(true)}}>Individuo</div>
        <div className={"selector-link" + (!viendoIndividuo ? ' is--selected' : '')} onClick={() => {setViendoIndividuo(false)}}>Empresa</div>
      </div>}
      {!!viendoIndividuo
        ? <div className="wrapper--scrollable-content__layout" >
          <div className="wrapper--scrollable-content__column" >
            <section className="perfil-section">
              <button className="button--user-avatar" title="Avatar" onClick={() => {inputRef.current.click()}}>
                <div className="button--user-avatar__content-wrapper">
                  <span className="button--user-avatar__notification-icon">+</span>
                  {avatar.url
                    ? <img className="button--user-avatar__image" src={avatar.url} alt="" />
                    : <div className="button--user-avatar__image" ><span className="button--user-avatar__text" >{(usuario.nombres ? usuario.nombres[0] : '') + (usuario.apellidos ? usuario.apellidos[0] : '')}</span></div>
                  }
                </div>
              </button>
              <input ref={inputRef} accept="image/*" style={{display: 'none'}} type="file" onChange={cambiarImagen} />
              <div className="input-label-combo">
                <input className={"inputNombres" + (nombres ? ' tiene-valor' : '')} id="inputNombres" type="text" onInput={(event) => {setNombres(event.target.value)}} value={nombres} />
                <label className="is--placeholder" htmlFor="inputNombres">Nombres</label>
              </div>
              <div className="input-label-combo">
                <input className={"inputApellidos" + (apellidos ? ' tiene-valor' : '')} id="inputApellidos" type="text" onInput={(event) => {setApellidos(event.target.value)}} value={apellidos} />
                <label className="is--placeholder" htmlFor="inputApellidos">Apellidos</label>
              </div>
              <div className="input-label-combo">
                <input className={"inputEmailDeContacto" + (apellidos ? ' tiene-valor' : '')} id="inputEmailDeContacto" type="text" onInput={(event) => {setEmailDeContacto(event.target.value)}} value={emailDeContacto} />
                <label className="is--placeholder" htmlFor="inputEmailDeContacto">Email de contacto</label>
              </div>
              <div className="input-label-combo">
                <input className={"inputTelefono" + (apellidos ? ' tiene-valor' : '')} id="inputTelefono" type="text" onInput={(event) => {setTelefono(event.target.value)}} value={telefono} />
                <label className="is--placeholder" htmlFor="inputTelefono">Telefono de contacto</label>
              </div>
              <h1 className="titulo sedes__title">Sede de preferencia</h1>
              <Selector titulo="Sede" selectedItem={espacio} items={espacios} icon="" onChange={setEspacio} folded={true} />
              <h1 className="titulo aspectosProfesionales__title">Aptitudes</h1>
              <div className="aptitudes-combo">
                <Selector titulo="Aptitud" className="aptitudes--selector" selectedItem={aptitud} items={aptitudesGenerales.filter(item => !aptitudes.find(aptitud => aptitud.id === item.id))} icon="" onChange={setAptitud} folded={true} />
                <button className="aptitud-button--nuevo" onClick={() => agregarAptitud(aptitud)}><i className="icon--plus"></i></button>
              </div>
              <div className="aptitudes">
                {aptitudes && aptitudes.map((item, index) =>
                  <div key={item.id} className="aptitud">
                    <span>{item.name}</span>
                    <button className="aptitud-button" onClick={() => quitarAptitud(item)}><i className="icon--times"></i></button>
                  </div>)}
              </div>
              <h1 className="titulo aspectosProfesionales__title">Información profesional</h1>
              <div className="input-label-combo">
                <textarea className={"text-area aspectosProfesionales" + (aspectosProfesionales ? ' tiene-valor' : '')} value={aspectosProfesionales} onInput={(event) => {setAspectosProfesionales(event.target.value)}} />
                <label className="is--placeholder">Aspectos profesionales</label>
              </div>
              <div className="input-label-combo">
                <textarea className={"text-area aspectosProfesionales" + (professionalHighlights ? ' tiene-valor' : '')} value={professionalHighlights} onInput={(event) => {setProfessionalHighlights(event.target.value)}} />
                <label className="is--placeholder">Me destaco en</label>
              </div>
              <div className="input-label-combo">
                <textarea className={"text-area aspectosProfesionales" + (professionalKeywords ? ' tiene-valor' : '')} value={professionalKeywords} onInput={(event) => { setProfessionalKeywords(event.target.value) }} />
                <label className="is--placeholder">Palabras clave</label>
              </div>
            </section>
          </div>
          {!props.isPortrait &&
            <div className="wrapper--scrollable-content__column" >
              <div className="membresia-wrapper">
                <h1 className="membresia__titulo">{usuario.contrato ? 'Tu membresía' : 'Sin Membresía'}</h1>
                  {usuario.contrato && <Membresia {...usuario.membresia} />}
              </div>
              <div>
                {(!!usuario.contrato && (usuario.contrato.cliente.tipo !== 'EMPRESA')) && adminAccess &&
                  <TarjetaDeContrato usuario={usuario} contrato={usuario ? usuario.contrato : null} adminAccess={adminAccess} onVerContrato={handleVerContrato} onFirmarContrato={handleFirmarContrato} />
                }
              </div>
            </div>
          }
        </div>
        : <div className="wrapper--scrollable-content__layout" >
          <div className="wrapper--scrollable-content__column" >
            <section className="perfil-section">
              <button className="button--user-avatar" title="Avatar" onClick={props.showMenu} style={{pointerEvents: 'none'}}>
                <div className="button--user-avatar__content-wrapper">
                  {/* <span className="button--user-avatar__notification-icon">+</span> */}
                  {usuario.empresa.logo
                    ? <img className="button--user-avatar__image" src={usuario.empresa.logo} alt="" />
                    : <div className="button--user-avatar__image"><span className="button--user-avatar__text">{(usuario.empresa.nombre ? usuario.empresa.nombre.substr(0,2) : '')}</span></div>
                  }
                </div>
              </button>
              {/* <input ref={inputRef} accept="image/*" style={{ display: 'none' }} type="file" onChange={cambiarImagenEmpresa} /> */}
              <div className="input-label-combo">
                <input className={"inputNombreDeEmpresa" + (nombreDeEmpresa ? ' tiene-valor' : '')} type="text" onInput={(event) => {setNombreDeEmpresa(event.target.value)}} value={nombreDeEmpresa} />
                <label className="is--placeholder" >Nombre</label>
              </div>
              <div className="input-label-combo">
                <textarea className={"text-area inputDescripcionDeEmpresa" + (descripcionDeEmpresa ? ' tiene-valor' : '')} type="text" onInput={(event) => {setDescripcionDeEmpresa(event.target.value)}} value={descripcionDeEmpresa} />
                <label className="is--placeholder" >Descripcion</label>
              </div>
              <div className="input-label-combo">
                <input className={"inputWEBEmpresa" + (webDeEmpresa ? ' tiene-valor' : '')} type="text" onInput={(event) => {setWEBDeEmpresa(event.target.value)}} value={webDeEmpresa} />
                <label className="is--placeholder">Pagina WEB</label>
              </div>

              {(empleados.length > 0) && <>
                <h1 className="titulo listaDeEmpleados__title">Integrantes</h1>
                <ol className="lista-de-empleados">
                  {empleados.sort(ordenPorNombreYApellido).map(item =>
                    <li key={item.id} className="tarjeta-de-empleado">
                      <TarjetaDeEmpleado item={item} conPuesto={true} >
                        {adminAccess &&
                          // <button className={"control--acceso-cuenta" + (item.accesoACuenta ? ' permitido' : ' no-permitido')} title="Acceso a créditos de la empresa" onClick={() => toggleAccesoACuenta(item)}>
                          <button className={"control--acceso-cuenta"} title={(item.accesoACuenta ? 'Con ' : 'Sin ') + "acceso a créditos de la empresa"} onClick={() => toggleAccesoACuenta(item)}>
                          {/* {item.accesoACuenta
                            ? <i className="icon--dollar"></i>
                            : <i className="icon--dollar"></i>
                          } */}
                          {item.accesoACuenta
                            ? <img className="control--icon" src="img/coin-green.png" alt="permitido" />
                            : <img className="control--icon" src="img/coin-red.png" alt="no permitido" />
                          }
                        </button>}
                        {adminAccess && <button className="control--remover-miembro" title="Quitar empleado"  onClick={() => confirmarRemover(item)}>Remover</button>}
                      </TarjetaDeEmpleado>
                    </li>
                  )}
                </ol>
              </>}

            {(staff.length > 0) && <>
              <h1 className="titulo listaDeEmpleados__title">Equipo Administrador</h1>
              <ol className="lista-de-empleados">
                {staff.sort(ordenPorNombreYApellido).map(item =>
                  <li key={item.id} className="tarjeta-de-empleado">
                    <TarjetaDeEmpleado item={item} >
                      {adminAccess && <button className="control--remover-miembro" onClick={() => removerAdministrador(item.id)}>Remover</button>}
                    </TarjetaDeEmpleado>
                  </li>
                )}
                {adminAccess && (!agregandoAdministrador
                  ? <li className="tarjeta-agregar-staff">
                    <button className="control--agregar-staff" onClick={() => { setAgregandoAdministrador(true) }}>Agregar al Equipo</button>
                  </li>
                  : <li className="tarjeta-agregar-staff">
                    <h2 className="titulo">Agregar al Equipo Administrador</h2>
                    <div className="layout--row">
                      <div className="layout--column">
                        <Selector titulo="Integrante..." selectedItem={nuevoAdministrador} items={empleados} icon="" onChange={setNuevoAdministrador} folded={true} />
                      </div>
                      <div className="layout--column layout--controles">
                        <button className="control--aceptar-miembro" onClick={agregarAdministrador}>
                          <i className="icon--check"></i>
                        </button>
                        <button className="control--rechazar-miembro" onClick={() => { setAgregandoAdministrador(false) }}>
                          <i className="icon--times"></i>
                        </button>
                      </div>
                    </div>
                  </li>)}
              </ol>
            </>}
            </section>
          </div>
          <div className="wrapper--scrollable-content__column" >
            {adminAccess && (empleadosPendientes.length > 0) && <section className="perfil-section">
              <h1 className="titulo listaDeEmpleados__title">Aceptar Integrantes</h1>
              <ol className="lista-de-empleados">
                {empleadosPendientes.sort(ordenPorNombreYApellido).map(item =>
                  <li className="tarjeta-de-empleado">
                    <TarjetaDeEmpleado item={item} conPuesto={true} >
                      <button className="control--aceptar-miembro" onClick={() => aceptarMiembro(item)}><i className="icon--check"></i></button>
                      <button className="control--rechazar-miembro" onClick={() => rechazarMiembro(item)}><i className="icon--times"></i></button>
                    </TarjetaDeEmpleado>
                  </li>
                )}
              </ol>
            </section>}
            <TarjetaDeContrato usuario={usuario} contrato={usuario ? usuario.contrato : null} adminAccess={adminAccess} onVerContrato={handleVerContrato} onFirmarContrato={handleFirmarContrato} />
          </div>
        </div>
      }

      <FixedCTA label="Guardar cambios" disabled={!datosCompletos} onClick={guardarCambios} />
    </div>
  );
}