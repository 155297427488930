import React, { useEffect, useState } from "react";
import { sistema } from "../../model/Sistema";
import Escritorio from "../EscritorioMulti";


export default ({mesa, color, seleccionados, onChange, usuariosPosibles, ...props}) => {

  const [puestos, setPuestos] = useState([]);
  const [puestosSeleccionados, setPuestosSeleccionados] = useState(seleccionados);
  const usuario = sistema?.usuario ?? null;
  const puedeAdministrar = sistema?.usuario?.contratoAdminAccess ?? false;
  const esFreelancer = sistema?.usuario?.contrato?.cliente?.tipo === 'FREELANCER';
  const [mesaSeleccionable, setMesaSeleccionable] = useState(false);

  useEffect(() => {
    if (mesa && mesa.puestos) {
      setPuestos(mesa.puestos.map(puesto => {
        const seleccionado = puestosSeleccionados.find(item => item.id === puesto.id);
        if (!!seleccionado) {
          if (!puesto.reservas || (puesto.reservas.length === 0)) {
            puesto.usuarios = seleccionado.usuarios;
            puesto.status = seleccionado.status;
          } else {
            // Quitamos la seleccion del puesto
            // if (puesto.usuarios[0].id !== seleccionado.usuarios[0].id) {
            //   console.log('quitamos seleccion de puesto', puesto.usuarios[0], seleccionado);
            //   puestoSeleccionado(puesto, null);
            // }
          }
        }
        return puesto;
      }));
      // if (esFreelancer || !puedeAdministrar) {
      //   if (mesa.puestos.find(puesto => !!puesto.usuarios && !!usuario && (puesto.usuarios[0].id === usuario.id) && !!puesto.reservas && !!puesto.reservas.length > 0)) {
      //     setMesaSeleccionable(false);
      //   } else {
      //     setMesaSeleccionable(usuariosPosibles.length > 0);
      //   }
      // } else {
      //   setMesaSeleccionable(usuariosPosibles.length > 0);
      // }
    }
  }, [mesa]);

  useEffect(() => {
    setPuestosSeleccionados(seleccionados);
  }, [seleccionados]);

  useEffect(() => {
    setMesaSeleccionable(!!usuariosPosibles && usuariosPosibles.length > 0);
  }, [usuariosPosibles]);

  const puestoSeleccionado = (item, usuarioSeleccionado) => {
    let nuevosPuestos = [...puestosSeleccionados.filter(actual => (actual.id !== item.id) && (!actual.usuarios || !usuarioSeleccionado || (actual.usuarios[0].id !== usuarioSeleccionado.id)))];
    const puestoAnterior = mesa.puestos.find(puesto => !!puesto.usuarios && !!usuarioSeleccionado && (puesto.usuarios[0].id === usuarioSeleccionado.id));

    console.log('puestoSeleccionado', usuarioSeleccionado, puestoAnterior, mesa);
    if (!!puestoAnterior) {
      puestoAnterior.usuarios = null;
      puestoAnterior.status = 'FREE';
    }

    if (!!usuarioSeleccionado) {
      item.usuarios = [usuarioSeleccionado];
      item.status = "TAKEN";
      nuevosPuestos.push(item);
      // console.log('puesto agregado', item, usuario);
    } else {
      item.usuarios = null;
      item.status = 'FREE';
      // console.log('puesto quitado', item);
    }
    setPuestosSeleccionados(nuevosPuestos);
    if (onChange) {
      onChange(mesa, nuevosPuestos);
    }
  }


  return (
    <div style={{backgroundColor: color}} className={"mesa mesa--" + (mesa ? mesa.cantidadDePuestos : 0)}>
        <ol className="mesa__puestos">
          {puestos && puestos.length >0 &&
          puestos.map((item, index) => (
            <li className="mesa__puesto" key={index}>
              <Escritorio disabled={!mesaSeleccionable} seleccionados={puestosSeleccionados} usuariosPosibles={usuariosPosibles} onClick={(usuario) => {puestoSeleccionado(item,usuario)}} habilitado={item.habilitado} posicion={(index < (puestos.length / 2)) ? 'TOP' : 'BOTTOM'} numero={item.id} status={item.status} reservas={item.reservas} usuarios={item.usuarios} />
            </li>
          ))}
        </ol>

    </div>
);
}