import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Selector from "../../components/Selector";
import TituloConBack from "../../components/TituloConBack";
import { sistema } from '../../model/Sistema';
import { getUIData, sendRequest } from '../../lib/serverAPI';
import Mesa from "../../components/MesaMulti";
import FixedCTA from "../../components/FixedCTA";
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { displayName } from "qrcode.react";
import APIManager from "../../managers/APIManager";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import moment from "moment";


export default ({ ...props }) => {

  const [mesa, setMesa] = useState(sistema && sistema.actual && sistema.actual.mesas && (sistema.actual.mesas.length > 0) && !sistema.actual.mesa ? sistema.actual.mesas[0] : (sistema.actual && sistema.actual.mesa ? sistema.actual.mesa : null));
  const [mesas, setMesas] = useState(sistema && sistema.actual && sistema.actual.mesas ? sistema.actual.mesas : []);
  const [mesasFolded, setMesasFolded] = useState(true);
  const [puestos, setPuestos] = useState(sistema && sistema.actual && sistema.actual.puestos ? sistema.actual.puestos : []);
  const [sector, setSector] = useState(sistema && sistema.actual && sistema.actual.sector ? sistema.actual.sector : null);
  const [sectorVisible, setSectorVisible] = useState(true);
  const [hayTour, setHayTour] = useState(false);
  const [hayFoto, setHayFoto] = useState(false);
  const [hayMapa, setHayMapa] = useState(false);
  const [hayColor, setHayColor] = useState(false);
  const [tourVisible, setTourVisible] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(false);
  const [usuariosPosibles, setUsuariosPosibles] = useState([]);
  const puedeAdministrar = sistema?.usuario?.contratoAdminAccess ?? false;
  const esFreelancer = sistema?.usuario?.contrato?.cliente?.tipo === 'FREELANCER';

  const [puestosAReservar, setPuestosAReservar] = useState([]);

  const [costoActual, setCostoActual] = useState(sistema.actual.costoPorPuesto * (sistema.actual.puestos?.length ?? 0));

  const [sinUsuariosDisponibles, setSinUsuariosDisponibles] = useState(false);
  const [sinUsuariosDisponiblesOpen, setSinUsuariosDisponiblesOpen] = useState(false);

  const verImagenes = sistema.verFotosDeSedes;

  const history = useHistory();
  if ((!sistema.actual || !sistema.actual.turno) && localStorage.actual) {
    sistema.actual = JSON.parse(localStorage.actual);
    if (!!sistema.actual.sector) {
      setSector(sistema.actual.sector);
    }
  }
  if (!sistema.actual || !sistema.actual.turno)
  {
    history.push('/posicion');
  }
  else
  {
    // levantamos las mesas del sector seleccionado (si es necesario)
    useEffect(() => {
      props.setSeccion('/reservas');

      setSector(sistema.actual.sector);
      setHayTour(!!sistema.actual.sector.tour);
      setHayFoto(!!sistema.actual.sector.imagen);
      setHayMapa(!!sistema.actual.sector.mapa);
      setHayColor(!!sistema.actual.sector.color);
      // setUsuariosPosibles(props.usuario.contrato.cliente.tipo === 'EMPRESA' ? Object.values(props.usuario.contrato.cliente.referente.empleados) : [props.usuario]);
      APIManager.getList('usuariosPosiblesParaReserva', { paraV1: true, turno: sistema.actual.turno, cliente: sistema.usuario.contrato.cliente, fecha: sistema.actual.fecha }, response => {
        if (!esFreelancer && !puedeAdministrar) {
          response.data.listado = response.data.listado.filter(item => item.id === sistema.usuario.id);
        }
        sistema.actual.usuariosPosibles = response.data.listado;
        if (!!sistema.actual.cancelando) {
          response.data.listado.push(sistema.actual.usuarioAsignado);
        }
        setUsuariosPosibles(response.data.listado);
        if (response.data.listado.length === 0) {
          setSinUsuariosDisponibles(true);
          setSinUsuariosDisponiblesOpen(true);
        }
      })
      getUIData('getEscritorios', {
        espacioID: sistema.actual.espacio.id,
        sectorID: sistema.actual.sector.id,
        fecha: sistema.actual.fecha,
        turno: sistema.actual.turno
      },
        (escritorios) => {
          if (escritorios.length > 0) {
            const mesaPreSeleccionada = !!sistema.actual.mesa ? escritorios.find(item => item.id === sistema.actual.mesa.id) : escritorios[0];
            if (!!sistema.actual.cancelando && !!mesaPreSeleccionada && mesaPreSeleccionada.reservas && !!sistema.actual.usuarioAsignado) {
              // Quitamos el usuarioAsignado
              mesaPreSeleccionada.reservas = mesaPreSeleccionada.reservas.filter(item => item.usuarioAsignado.id !== sistema.actual.usuarioAsignado.id);
              mesaPreSeleccionada.puestos.forEach(puesto => {
                puesto.reservas = puesto.reservas.filter(item => item.usuarioAsignado.id !== sistema.actual.usuarioAsignado.id);
              });
            }
            setMesa(mesaPreSeleccionada);
          }
          setMesas(escritorios);
        });
    }, []);
  }

  const quitarUsuarioEn = (puestoALiberar) => {
    console.log('quitarUsuario', puestoALiberar, mesa);
    const nuevaMesa = mesa;
    nuevaMesa.puestos.forEach(puesto => {
      if ((puesto.status === 'TAKEN') && (puesto.id === puestoALiberar.id)) {
        puesto.status = 'FREE';
        puesto.reservas = [];
        puesto.usuarios = null;
      }
    });
    setMesa(nuevaMesa);
  }

  const disponiblesDe = (escritorio) => {
    return escritorio ? escritorio.disponibles + " disponibles" : '';
  }

  const puestosSeleccionados = (nuevaMesa, nuevosPuestos) => {
    // setMesa(nuevaMesa);
    setPuestos(nuevosPuestos);
    const puestosPosibles = puestosReservables(nuevosPuestos);
    setPuestosAReservar(puestosPosibles);

    setCostoActual(sistema.actual.costoPorPuesto * nuevosPuestos.length);
  }

  const puestosReservables = (puestosDeseados, filtrarUsuarios = false) => {
    // Filtramos los puestos que tenian reservas (no se puede seleccionar un puesto que tenia reserva)
    let puestosReservables = puestosDeseados.filter(item => !!mesa.puestos.find(puesto => (puesto.id === item.id) && (!puesto.reservas || puesto.reservas.length === 0)));

    // Filtramos los puestos deshabilitados
    puestosReservables = puestosReservables.filter(item => !!mesa.puestos.find(puesto => (puesto.id === item.id) && (puesto.habilitado)));

    // Filtramos los puestos que tienen usuarios que no estan disponibles (porque ya tienen reserva para hoy en este turno)
    if (filtrarUsuarios) {
      puestosReservables = puestosReservables.filter(item => !!usuariosPosibles.find(miembro => miembro.id === item.usuarios[0].id));
    }
    console.log('puestosReservables', puestosReservables);
    return puestosReservables;
  }

  useEffect(() => {
    setCostoActual(sistema.actual.costoPorPuesto * puestosAReservar.length);
  }, [puestosAReservar]);

  const continuar = () => {
    sistema.actual.mesa = mesa;
    // sistema.actual.puestos = puestos;
    sistema.actual.puestos = puestosAReservar;
    sistema.actual.costo = costoActual;
    localStorage.actual = JSON.stringify(sistema.actual);
    history.push('/confirmar');
  }

  const toggleFullScreenImage = () => {
    setFullscreenImage(!fullscreenImage);
  }

  const textoDeAvisoPorDisponibilidad = (esFreelancer, puedeAdministrar) => {
    if (!!sistema.actual.turno) {
      return esFreelancer || !puedeAdministrar
        ? '¡Ya hay un puesto reservado para ' + (sistema.actual.turno.codigo !== 'DOBLE' ? 'el turno ' + sistema.actual.turno.titulo + ' d' : '') + 'el dia ' + moment(sistema.actual.fecha).locale('es').format('L') + ' en tu agenda!'
        : '¡Todos los miembros tienen puestos reservados para ' + (sistema.actual.turno.codigo !== 'DOBLE' ? 'el turno ' + sistema.actual.turno.titulo + ' d' : '') + 'el dia ' + moment(sistema.actual.fecha).locale('es').format('L') + ' !';
    } else {
      return '';
    }
  }

  useEffect(() => {
    const mesaAnteriorID = sistema?.actual?.mesa?.id ?? null;
    console.log('cambio', mesa, mesaAnteriorID, puestos, puestosAReservar, usuariosPosibles);
    setSectorVisible(true);
    // Correccion para marcar como "puestos a reservar" aquellos que cumplan con las condiciones
    setPuestosAReservar(puestosReservables(puestos, true));
    if (!!mesaAnteriorID && mesaAnteriorID !== mesa.id) {
      // Cambio de mesa, deshacemos la seleccion de miembros
      console.log('cambio de mesa!', mesa, puestosAReservar);
      puestosAReservar.forEach(item => quitarUsuarioEn(item));
      setPuestosAReservar([]);
      setPuestos([]);
    }
    // setPuestosAReservar(puestos.filter(item => mesa.puestos.find(puesto => (puesto.id === item.id) && (!puesto.reservas || puesto.reservas.length === 0))));
    sistema.actual.mesa = mesa;
  }, [mesa, puestos, usuariosPosibles]);

  return (
    <div className="seleccion-de-escritorio-page">
      <Dialog open={sinUsuariosDisponiblesOpen} onClose={ e => setSinUsuariosDisponiblesOpen(false)}>
        <DialogTitle>Dia completo</DialogTitle>
        <DialogContent>
          <DialogContentText>{textoDeAvisoPorDisponibilidad(esFreelancer, puedeAdministrar)}</DialogContentText>
        </DialogContent>
      </Dialog>
      <TituloConBack titulo="Elegí el asiento en la mesa que prefieras" />
      <Selector className="selector-mesa" titulo="Mesa" selectedItem={mesa} items={mesas} icon="" onChange={setMesa} folded={mesasFolded} showInfo={(item) => disponiblesDe(item)} />
      {!!props.isPortrait
        ? (!!hayFoto || !!hayMapa) && verImagenes &&
        <>
          <div className={"layout--image-wrapper " + !!sectorVisible ? ' sector-visible' : ' mesa-visible'}>
            <div className="image__toggle-wrapper">
              <label htmlFor="imageToggle">Foto</label>
              <Switch className="image__toggle" sx={{ m: 1 }} disableRipple focusVisibleClassName=".Mui-focusVisible" id="imageToggle" checked={!sectorVisible} onChange={() => setSectorVisible(!sectorVisible)} />
              <label htmlFor="imageToggle">Mapa</label>
            </div>
            {!!sectorVisible
              ? hayFoto && verImagenes && <div className="sector__column">
                {hayTour
                  ? <div className="sector__tour--switch">
                    <button className={"sector__tour--button" + (!tourVisible ? " active" : "")} onClick={() => {setTourVisible(false)}}>FOTO</button>
                    <button className={"sector__tour--button" + (tourVisible ? " active" : "")} onClick={() => {setTourVisible(true)}}>TOUR</button>
                  </div>
                  : null
                }
                {tourVisible
                  ? <iframe className="sector__image" title="Tour Virtual" width='100%' height='100%' src='https://my.matterport.com/show/?m=twtkPbsRaCd&help=0&play=1&qs=1&brand=0&gt=0&hr=0&mls=2&cloudEdit=1&sr=-2.85,.3&ss=91&hl=2' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
                  // : <div className="sector__image info-de-escritorio__image" style={!!mesa ? { backgroundImage: 'url(' + mesa.imagenes.sector.url + ')' } : null} alt="Imagen Del Sector" />
                  : <div className="sector__image info-de-escritorio__image" style={!!mesa ? { backgroundImage: 'url(' + sector.imagen + ')' } : null} alt="Imagen Del Sector" />
                }
              </div>
              // : <div className={(fullscreenImage ? "fullscreen-image " : "") + "mesa__image info-de-escritorio__image"} style={!!mesa ? {display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundImage: 'url(' + mesa.imagenes.mesa.url + ')' } : null} alt="Imagen de la Mesa" onClick={toggleFullScreenImage}>
              : !!hayMapa && verImagenes && <div className={(fullscreenImage ? "fullscreen-image " : "") + "mesa__image info-de-escritorio__image"} style={!!mesa ? {display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundImage: 'url(' + sector.mapa + ')' } : null} alt="Mapa del sector" onClick={toggleFullScreenImage}>
                {!!mesa && <i className={"icon--search-" + (fullscreenImage ? "minus" : "plus") +" fa-6x"} style={{opacity: 0.1}} />}
              </div>
            }
          </div>
          <Mesa mesa={mesa} sector={sector} seleccionados={puestos} usuariosPosibles={usuariosPosibles} onChange={puestosSeleccionados} {...props} />
        </>
        : (!!hayFoto || !!hayMapa) && verImagenes &&
        <div className="wrapper--scrollable-content__layout">
          <div className="wrapper--scrollable-content__column sector__column">
            {hayTour
              ? <div className="sector__tour--switch">
                <button className={"sector__tour--button" + (!tourVisible ? " active" : "")} onClick={() => {setTourVisible(false)}}>FOTO</button>
                <button className={"sector__tour--button" + (tourVisible ? " active" : "")} onClick={() => {setTourVisible(true)}}>TOUR</button>
              </div>
              : null
            }
            {tourVisible
              ? <iframe className="sector__image" title="Tour Virtual" width='100%' height='100%' src='https://my.matterport.com/show/?m=twtkPbsRaCd&help=0&play=1&qs=1&brand=0&gt=0&hr=0&mls=2&cloudEdit=1&sr=-2.85,.3&ss=91&hl=2' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
              // : <div className="sector__image info-de-escritorio__image" style={!!mesa ? { backgroundImage: 'url(' + mesa.imagenes.sector.url + ')' } : null} alt="Imagen Del Sector" ></div>
              : <div className="sector__image info-de-escritorio__image" style={!!mesa ? { backgroundImage: 'url(' + sector.imagen + ')' } : null} alt="Imagen Del Sector" ></div>
            }
          </div>
          <div className="wrapper--scrollable-content__column">
            {/* <div className={(fullscreenImage ? "fullscreen-image " : "") + "mesa__image info-de-escritorio__image"} style={!!mesa ? { display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: ( fullscreenImage ? 'zoom-out' : 'zoom-in'), backgroundImage: 'url(' + mesa.imagenes.mesa.url + ')' } : null} alt="Imagen de la Mesa" onClick={toggleFullScreenImage} > */}
            <div className={(fullscreenImage ? "fullscreen-image " : "") + "mesa__image info-de-escritorio__image"} style={!!mesa ? { display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: ( fullscreenImage ? 'zoom-out' : 'zoom-in'), backgroundImage: 'url(' + sector.mapa + ')' } : null} alt="Imagen de la Mesa" onClick={toggleFullScreenImage} >
              {!!mesa && <i className={"icon--search-" + (fullscreenImage ? "minus" : "plus") +" fa-6x"} style={{opacity: 0.1}} />}
            </div>
            <Mesa mesa={mesa} color={sector.color} seleccionados={puestos} usuariosPosibles={usuariosPosibles} onChange={puestosSeleccionados}  {...props}/>
          </div>
        </div>
      }
      {((!hayFoto && !hayMapa) || !verImagenes) && <Mesa mesa={mesa} seleccionados={puestos} usuariosPosibles={usuariosPosibles} onChange={puestosSeleccionados} {...props} />}
      <FixedCTA
        disabled={!puestosAReservar || (puestosAReservar.length === 0) || (!!sistema.actual.cancelando && puestosAReservar.length !== 1)}
        className={(costoActual === 0) && !sinUsuariosDisponibles ? 'sin-leyenda' : ''}
        titulo={(costoActual !== 0) ? (costoActual + " Créditos") : ''}
        leyendaPre={!sinUsuariosDisponibles ? 'Te quedarán' : ''}
        leyendaBold={!sinUsuariosDisponibles ? (props.usuario.creditos.balance - costoActual) : textoDeAvisoPorDisponibilidad(esFreelancer, puedeAdministrar)}
        leyendaPost={!sinUsuariosDisponibles ? "créditos" : ''}
        label="Confirmar"
        onClick={continuar}
      />
      {/* <FixedCTA disabled={!puestos || (puestos.length === 0)} className={(costoActual === 0) ? 'sin-leyenda' : ''}  titulo={(costoActual !== 0) ? (costoActual + " Créditos") : '' } leyendaPre="Te quedarán" leyendaBold={props.usuario.creditos.balance - costoActual} leyendaPost="créditos" label="Confirmar" onClick={continuar}/> */}
      {/* <FixedCTA disabled={!puestos || (puestos.length === 0)} label="Continuar" onClick={continuar} /> */}
    </div>
  );
}